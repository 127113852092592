import React, { memo, useEffect } from "react";
import PopUpComponents from "./PopUpComponents";
import Navbar from "../components/navbar/Navbar";
import EachRoute from "./EachRoute";
import { useContext } from "react";
import ResponsiveContext from "../context/ResponsiveContext";
import "../components/homeComponents/EboHome.css";
import useFetchEboData from "../hooks/useFetchEboData";

const MainRouter = () => {
  const { screenWidth } = useContext(ResponsiveContext);

  useFetchEboData();

  return (
    <div className="bg-[white]">
      <PopUpComponents />
      {screenWidth > 768 && <Navbar />}
      <EachRoute />
    </div>
  );
};

export default memo(MainRouter);
