//
import axios from "axios";
import { insideHubBoundary } from "./availablityChecks";

export const handleDefaultLocationFetch = async (
  setLocationData,
  locationData,
  isGoogleMapsScriptLoaded,
  setStoreStates
) => {
  try {
    const LC_TEMP_CART = JSON.parse(localStorage?.getItem("LC_TEMP_CART"));

    // Retrieve location from localStorage
    const localstorageLocation = JSON.parse(localStorage.getItem("U_LOCATION"));
    console.log("store states", isGoogleMapsScriptLoaded, setStoreStates);
    // Set selectedLocation based on localStorage or IP geolocation
    let selectedLocation;
    // if (!isGoogleMapsScriptLoaded) {
    //   await loadGoogleMapScriptPromise();
    // }

    // const currentLocationInfo = await getCurrentLocation();

    if (locationData.selectedLocation?.lat) {
      selectedLocation = locationData.selectedLocation;
    } else if (LC_TEMP_CART?.bookingAddress?.locationv2?.lat) {
      selectedLocation = LC_TEMP_CART?.bookingAddress?.locationv2;
    } else if (localstorageLocation?.lat) {
      selectedLocation = localstorageLocation;
    }

    // If selectedLocation doesn't have lat, fetch from IP geolocation
    if (!selectedLocation?.lat) {
      const ipapiRes = await axios.get("https://ipapi.co/json/");
      if (ipapiRes?.data) {
        selectedLocation =
          ipapiRes.data.region.toLowerCase().trim() === "west bengal"
            ? defaultSiliguriLocationData
            : defaultMumbaiLocationData;
      } else {
        selectedLocation = defaultMumbaiLocationData;
      }
    }

    // Check if the location falls within a specific hub's boundary
    let isPointInPolygon = await insideHubBoundary([
      selectedLocation.lng,
      selectedLocation.lat,
    ]);

    // If not, set default location for Mumbai and check again
    if (!isPointInPolygon.isInside) {
      selectedLocation = defaultMumbaiLocationData;
      isPointInPolygon = await insideHubBoundary([
        selectedLocation.lng,
        selectedLocation.lat,
      ]);
    }

    // Update localStorage and state with the selected location
    localStorage.setItem("U_LOCATION", JSON.stringify(selectedLocation));

    setLocationData((prevData) => ({
      ...prevData,
      selectedLocation: {
        ...selectedLocation,
        hubId: isPointInPolygon.foundInWhichHub,
      },
    }));

    return isPointInPolygon.foundInWhichHub;
  } catch (error) {
    console.error(error);
  }
};

const getCurrentLocation = () => {
  return new Promise((resolve, reject) => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        async (position) => {
          const lat = position.coords.latitude;
          const lng = position.coords.longitude;
          let result = await insideHubBoundary([lng, lat]);
          if (result.isInside) {
            const locationData = await reverseGeocode({ lat, lng });
            resolve(locationData);
          } else {
            const locationData = await reverseGeocode({ lat, lng });
            console.log("currente location data", locationData);

            resolve(defaultMumbaiLocationData);
          }
        },
        (error) => {
          console.log("Error getting location: " + error.message);
          reject(error);
        }
      );
    } else {
      reject(new Error("Geolocation not supported"));
    }
  });
};

const reverseGeocode = async (location) => {
  return new Promise((resolve, reject) => {
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const addressComponents = results[0].address_components;
          const addressDetails = {
            formatedAddress: results.find(
              (currAddress) => currAddress.formatted_address.length <= 120
            ).formatted_address,
            streetName:
              addressComponents.find((component) =>
                component.types.includes("route")
              )?.long_name || "",
            s_locality1:
              addressComponents.find((component) =>
                component.types.includes("sublocality_level_1")
              )?.long_name || "",
            s_locality2:
              addressComponents.find((component) =>
                component.types.includes("sublocality_level_2")
              )?.long_name || "",
            s_locality3:
              addressComponents.find((component) =>
                component.types.includes("sublocality_level_3")
              )?.long_name || "",
            city:
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "",
            pincode:
              addressComponents.find((component) =>
                component.types.includes("postal_code")
              )?.long_name || "",
            state:
              addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.long_name || "",
            premise:
              addressComponents.find((component) =>
                component.types.includes("premise")
              )?.long_name || "",
          };

          const addressLine1 =
            addressDetails.searchBarResultAddress?.split(",")[0] ||
            addressDetails.premise ||
            addressDetails.streetName ||
            addressDetails.route ||
            addressDetails.s_locality1 ||
            addressDetails.s_locality2 ||
            addressDetails.s_locality3 ||
            addressDetails.city ||
            addressDetails.pincode;

          resolve({
            city: addressDetails.city,
            state: addressDetails.state,
            pincode: addressDetails.pincode,
            addressLine1,
            addressLine2: addressDetails.formatedAddress,
            lat: location.lat,
            lng: location.lng,
          });
        } else {
          console.warn("No results found");
          reject(new Error("No results found"));
        }
      } else {
        console.error("Geocoder failed due to:", status);
        reject(new Error(`Geocoder failed due to: ${status}`));
      }
    });
  });
};

const loadGoogleMapScriptPromise = () => {
  return new Promise((resolve, reject) => {
    const script = document.createElement("script");
    script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
    script.defer = true;
    document.head.appendChild(script);

    // Set the global variable to true once the script is loaded
    script.onload = () => {
      resolve();
    };

    // Cleanup to prevent memory leaks
    return () => {
      document.head.removeChild(script);
    };
  });
};

const loadGoogleMapScript = ({
  setLocationData,
  locationData,
  storeStates,
  setStoreStates,
}) => {
  const script = document.createElement("script");
  script.src = `https://maps.googleapis.com/maps/api/js?key=${process.env.REACT_APP_GOOGLE_MAPS_KEY}&libraries=places`;
  script.defer = true;
  document.head.appendChild(script);

  // Set the global variable to true once the script is loaded
  script.onload = () => {
    setStoreStates((p) => ({
      ...p,
      isGoogleMapsScriptLoaded: true,
    }));

    handleDefaultLocationFetch(
      setLocationData,
      locationData,
      true, // isGoogleMapsScriptLoaded
      setStoreStates
    );
  };

  // Cleanup to prevent memory leaks
  return () => {
    document.head.removeChild(script);
  };
};
// Default location data
const defaultSiliguriLocationData = {
  lat: 26.716976545721117,
  lng: 88.42376316458154,
  addressLine1: "Sevoke Road",
  addressLine2:
    "12, Sevoke Rd, Ward 10, Janta Nagar, Siliguri, West Bengal 734001, India",
  city: "Siliguri",
  state: "West Bengal",
  pincode: "734001",
};

const defaultMumbaiLocationData = {
  lat: 19.02852317173213,
  lng: 73.05990261302381,
  addressLine1: "Kharghar Skywalk",
  addressLine2:
    "23H5+FX3, Kharghar Skywalk, Sector 3, Belpada, Kharghar, Navi Mumbai, Maharashtra 410210, India",
  city: "Navi Mumbai",
  state: "Maharashtra",
  pincode: "410210",
};
