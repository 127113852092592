import React, { memo } from "react";
import {
  BsInstagram,
  BsFacebook,
  BsLinkedin,
  BsFillHeartFill,
  BsTwitterX,
} from "react-icons/bs";
import { Link } from "react-router-dom";

function FooterRight() {
  return (
    <div className="footer-right">
      <div className="footer-right-cont">
        {/* <div className="footer-right-sub-cont1">
          <p className="footer-right-sub-cont1-p">Recognised By-</p>
          <img
            src="/img/startup-india-logo.svg"
            alt="startupIndia"
            className="footer-right-sub-cont1-i"
            loading="lazy"
          />
        </div> */}
        <div className="footer-right-sub-cont2 ">
          <h1
            style={{
              fontFamily: "'Josefin Sans', sans-serif",
            }}
            className="footer-right-logo  py-4  md:-ml-0.5 ml-[.5rem]  md:p-0 lg:p-0 text-center 
            text-4xl md:text-5xl text-[#2136d4] font-bold"
          >
            ebo
          </h1>

          <p className="footer-right-sub-cont2-p">
            Share your memories!
            <BsFillHeartFill />
          </p>
          <div className="footer-right-social flex justify-between">
            <Link to="https://www.instagram.com/ebo.now/" target="_blank">
              <BsInstagram />
            </Link>
            <Link to="https://www.facebook.com/ebo.nowfb" target="_blank">
              <BsFacebook />
            </Link>
            <Link to="https://www.linkedin.com/company/ebonow/" target="_blank">
              <BsLinkedin />
            </Link>
            <Link to="https://twitter.com/ebocares" target="_blank">
              <BsTwitterX />
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
}

export default memo(FooterRight);
