import { useEffect } from "react";
import { useParams } from "react-router-dom";

export default function PayUrlRedirect() {
  const { redirectingUrl } = useParams();
  useEffect(() => {
    if (typeof window !== "undefined") {
      const urlDecode = decodeURIComponent(redirectingUrl);
      if (isValidURL(urlDecode)) {
        window.location.href = urlDecode;
      }
      console.log("Redirecting to ", urlDecode);
    }
    // eslint-disable-next-line
  }, []);
}

function isValidURL(url) {
  // Regular expression for a basic URL pattern
  const urlPattern = /^(https?|ftp):\/\/[^\s/$.?#].[^\s]*$/;
  return urlPattern.test(url);
}
