import React, { useContext } from "react";
import AddressContext from "../../../../../context/AddressContext";
import AuthContext from "../../../../../context/AuthContext";
import { FaLocationPinLock } from "react-icons/fa6";
import CompRenderingContext from "../../../../../context/CompRenderingContext";
import StructuredAddressContainer from "../../StructuredAddressContainer";

export default function SavedAddresses({ states, locationData, setStates }) {
  const { allAddress } = useContext(AddressContext);
  const { setLoginModal, setLocationData } = useContext(CompRenderingContext);

  const {
    initialState: { user },
  } = useContext(AuthContext);

  if (states.isPredictionsShow || locationData.isAddressSaveModal) {
    return <></>;
  }

  if (user) {
    return (
      <div className="flex flex-col w-full ">
        {allAddress?.length > 0 && (
          <div className=" uppercase mb-4 text-[#afafaf] font-[500] ">
            SAVED ADDRESSES
          </div>
        )}

        {allAddress?.map((currAdd) => {
          return (
            <StructuredAddressContainer
              address={currAdd}
              key={currAdd._id}
              onClickCallback={() => {
                setLocationData((p) => ({
                  ...p,
                  isAddressSaveModal: false,
                  isLocationAddModalRender: false,
                }));
              }}
              isSelecteAddress={true}
              hideCheckIcon={true}
              hideEditRemove={true}
            />
          );
        })}
        <div
          onClick={() => {
            setLocationData((p) => ({
              ...p,
              isAddressSaveModal: true,
            }));
            setStates((p) => ({
              ...p,
              defaultUi: false,
              showMap: true,
            }));
          }}
          className="mirror-Animation before:w-[20px] font-[500] rounded-lg py-2 mt-2 mb-4 gap-[2px] px-3 items-center 
        justify-center blue-gradient text-[1rem] flex"
        >
          Add New Address
        </div>
      </div>
    );
  } else {
    return (
      <div
        onClick={() => {
          setLocationData((p) => ({
            ...p,
            isLocationAddModalRender: false,
            isAddressSaveModal: false,
          }));
          setTimeout(() => {
            setLoginModal(true);
          }, 500);
        }}
        className="w-full flex blue-gradient  mirror-Animation before:w-[20px] justify-center gap-1  items-center text-[1rem] rounded-lg min-h-[3rem] h-[3rem] mt-4  font-[500]  "
      >
        <FaLocationPinLock className="text-[1.25rem]" />
        Login to view Saved addresses
      </div>
    );
  }
}
