import React from "react";
export default function DownloadApp() {
  return (
    <div className="flex items-center justify-between p-2">
      <div className="flex gap-4  p-2 rounded-[.5rem] overflow-hidden bg-gradient-to-r  from-blue-50   to-white">
        <h1
          style={{
            fontFamily: "'Josefin Sans', sans-serif",
          }}
          className="relative top-[4px] text-[2rem] flex justify-center items-center text-[#2136d4] font-[800]  "
        >
          ebo
        </h1>
        <div className="text-[.75rem]">
          <p className="">Book service faster</p>
          <p className="leading-[1] text-[gray]">
            with the{" "}
            <span
              style={{
                fontFamily: "'Josefin Sans', sans-serif",
                fontSize: "14px",
              }}
            >
              ebo
            </span>{" "}
            app
          </p>
        </div>
      </div>
      <div className="p-2">
        <a
          href="https://ebo.onelink.me/HcT2/tws2pnj0"
          className="px-2 py-[6px]  blue-gradient hover:text-[white] focus:text-[white] text-[.875rem] rounded-[5px]"
        >
          Download the app
        </a>
      </div>
    </div>
  );
}
