import React, {
  createContext,
  useContext,
  useEffect,
  useState,
  memo,
  useCallback,
} from "react";
// import bcrypt from "bcryptjs";
import axios from "axios";
// import moment from "moment";
import CompRenderingContext from "./CompRenderingContext";
import {
  checkOTPAndVerifyApi,
  generate_otp,
  get_user,
  grant_access_for_admin,
  register_user,
} from "../api/userApi";
import { useSearchParams, useNavigate, useLocation } from "react-router-dom";

const AuthContext = createContext();

export const AuthProvider = memo(({ children }) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const isBrowser = typeof window !== "undefined";

  // ------------- ** LOCAL STORAGE ** ----------------
  const user = isBrowser ? JSON.parse(localStorage?.getItem("user")) : null;

  // ------------ ** CONTEXTS ** ------------------------------

  const {
    setLoginModal,
    setIsLoginModal,
    universalModalsRenderInfo,
    setSuccessMsg,
    locationData,
  } = useContext(CompRenderingContext);

  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // ---------------- ** STATES ** ------------------------------

  // otp state
  const [serverRes, setServerRes] = useState({
    isLoading: false,
    data: null,
  });

  //initial user state
  const [initialState, setInitialState] = useState({
    user: user ? user : null,
    isError: false,
    isSuccess: false,
    isLoading: false,
    message: "",
    type: "",
  });

  //updating user details state
  const [isSaving, setIsSaving] = useState(false);

  //phone input state
  const [number, setNumber] = useState("");

  //state errors
  const [error, setError] = useState("");

  //form rendering on condition
  const [authPage, setAuthPage] = useState({
    login: true,
    otp: false,
    register: false,
  });

  // ---------- ** USE EFFECTS ** ----------------

  useEffect(() => {
    let showLoginModalTimeout;

    const { pathname } = location;
    if (
      initialState.user === null &&
      !locationData.isLocationAddModalRender &&
      pathname === "/"
    ) {
      showLoginModalTimeout = setTimeout(() => {
        setLoginModal(true);
      }, 30000);
      return () => {
        setLoginModal(false);
        clearTimeout(showLoginModalTimeout);
      };
    }
    //eslint-disable-next-line
  }, [initialState.user]);

  useEffect(() => {
    const accessToken = searchParams.get("accessToken");
    if (accessToken) {
      console.log("access token");
      window && handleAdminAccess(accessToken);
    }
    // eslint-disable-next-line
  }, [searchParams]);

  // ----------------- ** FUNCTIONS ** ----------------

  const handleAdminAccess = async (accessToken) => {
    localStorage.clear();
    console.log("access token", accessToken);
    const res = await grant_access_for_admin({
      url: REACT_APP_BACKEND_URL,
      accessToken,
    });

    if (res.isSuccess) {
      setInitialState({
        ...initialState,
        user: res.user,
        isLoading: false,
      });
      localStorage.setItem("user", JSON.stringify(res.user));
    }
    handleRemoveQueryParams();
  };

  const handleRemoveQueryParams = () => {
    // Extract the pathname from the current location
    const { pathname } = location;

    // Replace the current URL without query parameters
    navigate(pathname);
  };

  //for closing sidebar
  const closeSideBar = useCallback(() => {
    setIsLoginModal(false);
    //eslint-disable-next-line
  }, []);

  /**
   * initialize the opt generation
   */
  const otpGeneration = async (e) => {
    e && e.preventDefault();
    try {
      setServerRes({
        ...serverRes,
        isLoading: true,
      });
      const res = await generate_otp({ number });
      if (res.isSuccess) {
        setServerRes({
          isLoading: false,
          data: res,
        });
        // will open the otp page
        setAuthPage({
          ...authPage,
          otp: true,
          login: false,
        });
        setSuccessMsg({
          msg: "OTP Sent",
          isRender: true,
        });
      } else {
        setServerRes({
          ...serverRes,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const getUser = useCallback(async () => {
    try {
      setInitialState({
        ...initialState,
        isLoading: true,
      });
      const res = await get_user({ url: REACT_APP_BACKEND_URL, number });
      if (res.isSuccess) {
        setInitialState({
          ...initialState,
          user: res.user,
          isLoading: false,
        });
        window && localStorage.setItem("user", JSON.stringify(res.user));
        // window.location.reload();
      } else {
        setInitialState({
          ...initialState,
          isLoading: false,
        });
      }
    } catch (error) {
      console.log(error);
      setInitialState({
        ...initialState,
        isLoading: false,
      });
    }
  }, [REACT_APP_BACKEND_URL, number, initialState]);

  //registering new user
  const registerUser = useCallback(
    async (formData, isCloseSideBar) => {
      try {
        setInitialState((prev) => ({ ...prev, isLoading: true }));
        const res = await register_user({
          url: REACT_APP_BACKEND_URL,
          formdata: formData,
        });
        if (res.isSuccess) {
          window && localStorage.setItem("user", JSON.stringify(res.user));
          navigate("/");
          setSuccessMsg({
            msg: `Welcome, ${
              res.user.username && res.user.username !== "User"
                ? res.user.username
                : ""
            } to ebo`,
            isRender: true,
          });
          setInitialState((prev) => ({
            ...prev,
            isLoading: false,
            user: res.user,
          }));
        } else {
          setInitialState((prev) => ({
            ...prev,
            isLoading: false,
          }));
        }
        if (isCloseSideBar) {
          closeSideBar();
          setAuthPage({ login: true, otp: false, register: false });
        }
      } catch (error) {
        console.log(error);
        setInitialState((prev) => ({
          ...prev,
          isLoading: false,
        }));
      }
    },
    [REACT_APP_BACKEND_URL, closeSideBar]
  );

  const checkOTP = async (inputedOTP) => {
    const res = await checkOTPAndVerifyApi({
      otp: inputedOTP,
      hashOtp: serverRes.data.hash,
      phoneNumber: number,
    });
    if (res.isSuccess) {
      if (res.isRegistered) {
        setInitialState({
          ...initialState,
          user: res.user,
          isLoading: false,
        });
        window && localStorage.setItem("user", JSON.stringify(res.user));
        setAuthPage({
          login: true,
          otp: false,
          register: false,
        });
        setIsLoginModal(false);
        setLoginModal(false);
        navigate("/");
        setSuccessMsg({
          msg: "Logged In",
          isRender: true,
        });
      } else {
        setSuccessMsg({
          msg: "OTP Verified",
          isRender: true,
        });
        setAuthPage({
          otp: false,
          login: false,
          register: true,
        });
        await registerUser(
          {
            phoneNumber: number,
            nameTitle: "",
            gender: "",
            name: "User",
            email: "",
            dateOfBirth: "",
            phoneUpdate: true,
            emailUpdate: true,
            whatsappUpdate: true,
          },
          false
        );
      }
      return { matched: true };
    } else {
      return { matched: false };
    }
  };

  //edit user details
  const editUser = useCallback(
    async (userData) => {
      try {
        setIsSaving(true);
        const res = await axios.post(
          `${REACT_APP_BACKEND_URL}/api/users/edit-user`,
          userData,
          {
            headers: {
              Authorization: "Bearer " + user.token,
            },
          }
        );
        setIsSaving(false);
        if (res.status === 200) {
          setInitialState({
            ...initialState,
            isSuccess: true,
            user: res.data.user,
          });
          window && localStorage.setItem("user", JSON.stringify(res.data.user));
        } else {
          console.log("Error in updating user");
        }

        return res.data;
      } catch (e) {
        console.log(e);
      }
    },
    [REACT_APP_BACKEND_URL, user, initialState]
  );

  //log out a user
  const logout = () => {
    window && localStorage.clear();
    setInitialState((prev) => ({ ...prev, user: null }));
    setAuthPage({ login: true, otp: false, register: false });
    window.location.href = "/";
  };

  const resendOTPFunc = async () => {
    await otpGeneration();
  };

  //input border css
  const inputBorder = "border-b-[1px] border-[rgba(0,0,0,0.3)] py-3 my-3";

  return (
    <AuthContext.Provider
      value={{
        user,
        inputBorder,
        authPage,
        setAuthPage,
        number,
        setNumber,
        initialState,
        setInitialState,
        closeSideBar,
        registerUser,
        logout,
        error,
        setError,
        resendOTPFunc,
        editUser,
        isSaving,
        otpGeneration,
        checkOTP,
        serverRes,
        getUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
});

export default AuthContext;
