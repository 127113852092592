import React, { useContext, lazy, Suspense } from "react";

// -------------- ** CONTEXTS ** ----------------
import CompRenderingContext from "../context/CompRenderingContext";

// -------------------- ** COMPONENTS ** ----------------
import RewardBoxDetail from "../components/wallet/RewardBoxDetail";
import ConfirmationDoneUi from "../assets/ConfirmationDoneUi";
import { AnimatePresence } from "framer-motion";
// import SelectLocationModal from "../components/modals/locationWorkModals/SelectLocationModal";
import AddLocationsIdx from "../components/myAddress/subComponents/addLocationV2/AddLocationsIdx";
import WarningModalv2 from "../assets/WarningModalv2";
import AddAndModiAddress from "../components/myAddress/subComponents/AddAndModiAddress/AddAndModiAddress";
import Toast1 from "../components/modals/toastModals/Toast1";

const InstructionInputBox = lazy(() =>
  import("../components/checkout/subcomponents/InstructionInputBox")
);
const Bill = lazy(() => import("../components/popupModal/Bill"));
const CouponModal = lazy(() => import("../assets/couponModal/CouponModal"));
const ToastMsgModal = lazy(() => import("../assets/ToastMsgModal"));
const SuccessModal = lazy(() => import("../assets/SuccessModal"));
const LoginPopUpModal = lazy(() =>
  import("../components/authSidebar/LoginPopUpModal")
);
const AddonDetailsCard = lazy(() =>
  import("../components/cards/AddonDetailsCard")
);
const Sidebar = lazy(() => import("../components/authSidebar/Sidebar"));
const AddressSelector = lazy(() =>
  import("../components/addressSelector/AddressSelector")
);
const PopUp = lazy(() => import("../components/popupModal/PopUp"));
const Cancellation = lazy(() =>
  import("../components/popupModal/Cancellation")
);
const Rating = lazy(() => import("../components/popupModal/Rating"));
const WarningModal = lazy(() =>
  import("../components/warningModal/WarningModal")
);
const ReferralAppliedModal = lazy(() =>
  import("../assets/ReferralAppliedModal")
);

export default function PopUpComponents() {
  // ------------ ** CONTEXTS ** -------------------------
  const {
    isPopup,
    boxInfo,
    isRating,
    showBill,
    toastMsg,
    successMsg,
    loginModal,
    cancelData,
    setIsPopup,
    couponModal,
    setIsRating,
    warningModal,
    isLoginModal,
    setLoginModal,
    isAddressSelector,
    isAddonDetailCard,
    instructionInputBox,
    referralAppliedModal,
    setIsAddressSelector,
    confrimationDoneUiRender,
    setConfirmationDoneUiRender,
    universalModalsRenderInfo,
    setUniversalModalsRenderInfo,
    locationData,
    setLocationData,
  } = useContext(CompRenderingContext);

  return (
    <div className="pop-up-components">
      <AddLocationsIdx />
      {/* <AnimatePresence>
        {universalModalsRenderInfo.selectLocationModal.isRender && (
          <SelectLocationModal
            modalsRenderInfo={universalModalsRenderInfo}
            setModalsRenderInfo={setUniversalModalsRenderInfo}
          />
        )}
      </AnimatePresence> */}
      <WarningModalv2
        parentStates={universalModalsRenderInfo}
        setParentStates={setUniversalModalsRenderInfo}
      />

      <AnimatePresence>
        {locationData.addAndModiAddress.isRender && (
          <AddAndModiAddress
            modalsRenderInfo={locationData}
            setModalsRenderInfo={setLocationData}
          />
        )}
      </AnimatePresence>
      <AnimatePresence>
        {locationData.toast1.isRender && (
          <Toast1
            modalsRenderInfo={locationData}
            setModalsRenderInfo={setLocationData}
          />
        )}
      </AnimatePresence>

      <ConfirmationDoneUi
        confrimationDoneUiRender={confrimationDoneUiRender}
        setConfirmationDoneUiRender={setConfirmationDoneUiRender}
      />

      {isLoginModal && (
        <Suspense fallback={<Spinner />}>
          <Sidebar />
        </Suspense>
      )}
      {isAddonDetailCard.isRender && (
        <Suspense fallback={<Spinner />}>
          <AddonDetailsCard />
        </Suspense>
      )}

      {showBill.isRender && (
        <Suspense fallback={<Spinner />}>
          <Bill prepaidDiscount={showBill.prepaidDiscount} />
        </Suspense>
      )}

      {toastMsg.isRender && (
        <Suspense fallback={<Spinner />}>
          <ToastMsgModal />
        </Suspense>
      )}
      {successMsg.isRender && (
        <Suspense fallback={<Spinner />}>
          <SuccessModal />
        </Suspense>
      )}
      {couponModal.isRender && (
        <Suspense fallback={<Spinner />}>
          <CouponModal />
        </Suspense>
      )}

      {boxInfo.isRender && (
        <Suspense fallback={<Spinner />}>
          <RewardBoxDetail
            name={boxInfo.name}
            handleClick={boxInfo.handleClick}
          />
        </Suspense>
      )}

      <AnimatePresence>
        {warningModal.isRender && (
          <Suspense fallback={<Spinner />}>
            <WarningModal />
          </Suspense>
        )}
      </AnimatePresence>

      {cancelData.isCancel && (
        <Suspense fallback={<Spinner />}>
          <Cancellation />
        </Suspense>
      )}

      <AnimatePresence>
        {isRating.isRender && (
          <Suspense fallback={<Spinner />}>
            <Rating isRating={isRating} setIsRating={setIsRating} />
          </Suspense>
        )}
      </AnimatePresence>
      {isPopup && (
        <Suspense fallback={<Spinner />}>
          <PopUp
            setIsPopup={setIsPopup}
            isPopup={isPopup}
            heading={"remove from wishlist"}
            subheading="Do you want to remove this from wishlist?"
            buttons={[
              {
                text: "remove",
                type: "basic",
              },

              { text: "move to cart", type: "primary" },
            ]}
          />
        </Suspense>
      )}

      <AnimatePresence>
        {isAddressSelector && (
          <Suspense fallback={<Spinner />}>
            <AddressSelector setIsAddressSelector={setIsAddressSelector} />
          </Suspense>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {loginModal && (
          <Suspense fallback={""}>
            <LoginPopUpModal
              loginModal={loginModal}
              setLoginModal={setLoginModal}
            />
          </Suspense>
        )}
      </AnimatePresence>

      {referralAppliedModal && (
        <Suspense fallback={""}>
          <ReferralAppliedModal />
        </Suspense>
      )}

      {instructionInputBox.isRender && (
        <Suspense fallback={""}>
          <InstructionInputBox />
        </Suspense>
      )}
    </div>
  );
}

const Spinner = () => {
  return (
    <div className="fixed top-0 right-0 left-0 bottom-0 flex items-center justify-center">
      <div className="loader w-[2rem] "></div>
    </div>
  );
};
