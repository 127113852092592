import axios from "axios";

const logUserLocationApi = async ({ isUserLoggedIn }) => {
  try {
    let tags = isUserLoggedIn ? ["loggedIn"] : ["notLoggedIn"];
    // Check if the key "trafficexist" is present and has a value of true in session storage
    const trafficExists = sessionStorage.getItem("trafficexist");

    if (trafficExists !== "true") {
      // If the key doesn't exist or has a value other than true, proceed with the API call

      // First API: ip-api.com
      const ipApiUrl = `https://ipapi.co/json/`;
      const ipApiResponse = await axios.get(ipApiUrl);

      if (ipApiResponse.data) {
        const response = await axios.post(
          `${process.env.REACT_APP_BACKEND_URL}/api/v2/`,
          {
            options: {
              operation: "logUserLocation",
              ipApiResponse: ipApiResponse.data,
              tags,
            },
          }
        );
        console.log("traffic", response.data); // Assuming server responds with data
      }

      // Set the key "trafficexist" in session storage to true
      sessionStorage.setItem("trafficexist", "true");
    } else {
      // If the key "trafficexist" is true, do not execute the API call
      console.log("Traffic information already logged for this session.");
    }
  } catch (error) {
    console.error("Error logging user location:", error);
  }
};
/**
 * in order to generate new otp
 * @param {Object} : {url: base url , number: phone number}
 * @returns {Promise} : returns a promise that will be resolved with an object or rejects with an error
 */
const generate_otp = async ({ number }) => {
  try {
    const res = await axios.get(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/generateotp/${number}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "wrong respose from the server",
      isExist: false,
      hash: null,
    };
  }
};

const checkOTPAndVerifyApi = async ({ otp, hashOtp, phoneNumber }) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/checkotp/`,
      { otp, hashOtp, phoneNumber }
    );

    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
      message: error.message,
    };
  }
};

const get_user = async ({ url, number }) => {
  try {
    const res = await axios.get(`${url}/api/users/getUser/${number}`);
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "error",
      user: null,
    };
  }
};
const update_user_api = async ({ queryObj, updateObj, projectionString }) => {
  try {
    const token = JSON.parse(localStorage?.getItem("user")).token;

    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/update-user-by-query`,
      { queryObj, updateObj, projectionString },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "error",
      user: null,
    };
  }
};
/**
 * in user data on the basis of number
 * @param {Object} : {url: base url , number: phone number}
 * @returns {Promise} : returns a promise that will be resolved with an object {      isSuccess: boolean,
 *  isError: boolean,
 *  message: String,
 *  user: object} or rejects with an error
 */
const register_user = async ({ url, formdata }) => {
  try {
    const res = await axios.post(`${url}/api/users/register`, formdata);
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: "error",
      user: null,
    };
  }
};

const grant_access_for_admin = async ({ url, accessToken }) => {
  try {
    const res = await axios.get(
      `${url}/api/users/grant_access_for_admin/${accessToken}`
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
};
const deleteUserPermanentlyApi = async ({ phoneNumber, otp, otpHash }) => {
  try {
    const res = await axios.post(
      `${process.env.REACT_APP_BACKEND_URL}/api/users/deleteUserPermanently`,
      { phoneNumber, otp, otpHash }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
    };
  }
};

export {
  generate_otp,
  get_user,
  register_user,
  grant_access_for_admin,
  update_user_api,
  checkOTPAndVerifyApi,
  deleteUserPermanentlyApi,
  logUserLocationApi,
};
