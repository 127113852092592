import React,  { createContext, useEffect, useState } from "react";

const ResponsiveContext = createContext();

export const ResponsiveProvider = ({ children }) => {


  const isBrowser = typeof window !== "undefined";
  const initialScreenWidth = isBrowser ? window.innerWidth : 1200; // Set a reasonable default width

  const [screenWidth, setScreenWidth] = useState(initialScreenWidth);

  const [colors] = useState({
    primary: "#2136D4",
  });

  const [background] = useState({
    lightsky: "#F0F5FE",
  });

  const handleResize = () => {
    setScreenWidth(isBrowser ? window.innerWidth : 1200);
  };

  useEffect(() => {
    setScreenWidth(initialScreenWidth);
    if (isBrowser) {
      window.addEventListener("resize", handleResize);
      return () => {
        window.removeEventListener("resize", handleResize);
      };
    }
    //eslint-disable-next-line
  }, [isBrowser]);

  return (
    <ResponsiveContext.Provider
      value={{ screenWidth, colors, background }}
    >
      {children}
    </ResponsiveContext.Provider>
  );
};

export default ResponsiveContext;

