import React, { memo, useContext } from "react";
import "./Footer.css";
import FooterBottom from "./FooterBottom";
import FooterLeft from "./FooterLeft";
import FooterRight from "./FooterRight";
import FootMoreAboutUs from "./FootMoreAboutUs";
import HomePageContext from "../../context/HomepageContext";

function Footer() {
  const { homeInitialData } = useContext(HomePageContext);

  if (homeInitialData.isFetching) return null;
  return (
    <footer className="w-full flex flex-col">
      <div className="ebo-footer w-full">
        <FooterLeft />
        <FooterRight />
      </div>
      <FooterBottom />
      <FootMoreAboutUs />
    </footer>
  );
}

export default memo(Footer);
