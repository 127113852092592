import React, { useContext, memo, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import ProductPreviewContext from "../../../context/ProductPreviewContext";
import ElsePopup from "../../homeComponents/ElsePopUp";
import { get_banners } from "../../../api/bannersApi";
import ResponsiveContext from "../../../context/ResponsiveContext";

function CategoriesList({ handelCloseOfContainer }) {
  const { resetPagePositions } = useContext(ProductPreviewContext);

  return (
    <div className="search-popular-categories flex flex-col">
      <h3 className="search-popular-categories-h3 text-[#565050] text-[1.2rem]">
        Popular Categories
      </h3>
      <Cards
        handelCloseOfContainer={handelCloseOfContainer}
        resetPagePositions={resetPagePositions}
      />
    </div>
  );
}

const Cards = memo(({ handelCloseOfContainer, resetPagePositions }) => {
  const [pageData, setPageData] = useState({
    isLoading: true,
    data: null,
  });
  const { screenWidth } = useContext(ResponsiveContext);

  useEffect(() => {
    pageData.data === null && handleCarouselsGet();

    // eslint-disable-next-line
  }, []);

  const handleCarouselsGet = async () => {
    try {
      const queryObject = {
        placeType: "home_top_categories",
        $or: [{ device: "both" }, { device: screenWidth >= 768 ? "lg" : "sm" }],
      };
      setPageData((p) => ({ ...p, isLoading: true }));
      const res = await get_banners({
        queryObject,
        skip: 0,
        limit: 10,
        sortingObj: { placementPosition: "asc" },
      });
      if (res.isSuccess) {
        setPageData((p) => ({ ...p, data: res.result, isLoading: false }));
      } else {
        setPageData((p) => ({ ...p, isLoading: false }));
      }
    } catch (error) {
      console.log(error.message);
    }
  };

  return (
    <div
      style={{ rowGap: ".2rem" }}
      className="grid gap-[1rem] md:gap-[3rem] w-[95%] md:w-[90%] md:max-w-[35rem] mt-[1.5rem]  grid-cols-4 "
    >
      {pageData.isLoading ? (
        <div className="w-[25rem] h-full flex items-center justify-center ">
          <div className="loader w-[1.5rem]"></div>
        </div>
      ) : (
        pageData.data.map((currCat) => {
          if (currCat.name === "Else") {
            return (
              <div
                key={currCat._id}
                onClick={() => {
                  resetPagePositions();
                  handelCloseOfContainer();
                }}
                style={{ boxShadow: "0 0 2px #d9d9d9 " }}
                className={`flex items-center rounded-lg overflow-hidden col-span-${currCat.cardsCapture} justify-center gap-2 cursor-pointer`}
              >
                <ElsePopup currCat={currCat} />
              </div>
            );
          }

          return (
            <Link
              to={currCat.redirectingUrl}
              key={currCat._id}
              onClick={() => {
                resetPagePositions();
                handelCloseOfContainer();
              }}
              style={{ boxShadow: "0 0 2px #d9d9d9 " }}
              className={`flex rounded-lg overflow-hidden items-center col-span-${currCat.cardsCapture} justify-center gap-2 cursor-pointer`}
            >
              <img
                src={currCat.bannerImgUrl}
                className="w-full"
                alt={currCat.name}
                loading="lazy"
                title={currCat.name}
              />
            </Link>
          );
        })
      )}
    </div>
  );
});

export default memo(CategoriesList);
