import React, { useState } from "react";
import "./EboHome.css";
import { EBO_PHONE_NUMBER } from "../../utils/constants";

const ElsePopup = ({ curr, width, height }) => {
  const [modal, setModal] = useState(false);

  const toggleModal = () => {
    setModal(!modal);
  };

  if (modal) {
    document.body.classList.add("ebo-home-catagory-active-modal");
  } else {
    document.body.classList.remove("ebo-home-catagory-active-modal");
  }

  return (
    <>
      <img
        src={curr.bannerImgUrl}
        style={{
          resizeMode: "stretch",
          width,
          height,
        }}
        alt={curr.name}
        loading="lazy"
        title={curr.name}
      />
      {modal && (
        <div className="ebo-home-catagory-modal">
          <div
            className="ebo-home-catagory-overlay"
            onClick={toggleModal}
          ></div>
          <div className="ebo-home-catagory-modal-content rounded-lg">
            <a
              href={`https://wa.me/${EBO_PHONE_NUMBER}`}
              target="_blank"
              rel="noreferrer noopener"
            >
              <img src="img/customizePopUpImg.svg" alt="popup" />
            </a>
          </div>
        </div>
      )}
    </>
  );
};

export default ElsePopup;
