import React, {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { get_product_by_query_object } from "../api/productApi";
import { useLocation, useNavigate } from "react-router-dom";
import { get_banners } from "../api/bannersApi";
import ResponsiveContext from "./ResponsiveContext";
import CompRenderingContext from "./CompRenderingContext";

const ProductPreviewContext = createContext();

export const ProductPreviewProvider = ({ children }) => {
  // ----------------- ** REACT ROUTER HOOKS ** ---------------
  const location = useLocation();
  const navigate = useNavigate();
  const { screenWidth } = useContext(ResponsiveContext);
  const { locationData } = useContext(CompRenderingContext);

  // -------------------** CONTEXTS ** ---------------------
  const REACT_APP_BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

  // ---------------------- ** STATES ** --------------------
  const [previewProducts, setPreviewProducts] = useState({
    previewProductData: [],
    isLoading: false,
  });

  const [productListingpageBanners, setProductListingPageBanners] = useState({
    topbanner: "",
    inBetweenProductBanner1: "",
    inBetweenProductBanner2: "",
  });

  const [showFilter, setShowFilter] = useState(false);
  const [totalProductsCount, setTotalProductsCount] = useState(0);
  const [currPage, setCurrPage] = useState({
    page: 0,
    isLast: false,
  }); // for pagination purpose in products preview page
  const [currUserPos, setCurrUserPos] = useState(0);

  // --------------- ** USE EFFECTS ** ------------------

  useEffect(() => {
    const pattern1 = /product/;
    const pattern2 = /checkout/;
    if (
      !(pattern1.test(location.pathname) || pattern2.test(location.pathname))
    ) {
      resetPagePositions();
    }
    handleProductbannersFetch();
    // eslint-disable-next-line
  }, [location]);

  // ------------ ** FUNCTIONS **-----------------

  const handleProductbannersFetch = async () => {
    try {
      if (
        !decodeURIComponent(location.pathname)
          .trim()
          .includes(decodeURIComponent("/products/").trim())
      ) {
        return;
      }
      const res = await get_banners({
        queryObject: {
          placeType: "product_listing_page_banner",
          or: [{ device: "both" }, { device: screenWidth > 768 ? "lg" : "sm" }],
        },
        limit: 10,
      });
      if (res.isSuccess) {
        let topPreviewBanner = "";
        let inBetweenPreviewBanner1 = "";
        let inBetweenPreviewBanner2 = "";

        res.result.forEach((curr) => {
          if (parseInt(curr.placementPosition) === 1) {
            // working for top preview banner

            if (curr.showForParticularUrl.length === 0) {
              topPreviewBanner = curr;
            } else {
              curr.showForParticularUrl.forEach((url) => {
                if (
                  decodeURIComponent(location.pathname)
                    .trim()
                    .includes(decodeURIComponent(url).trim())
                ) {
                  topPreviewBanner = curr;
                }
              });
            }
          } else if (parseInt(curr.placementPosition) === 2) {
            // working for in between preview banner
            if (curr.showForParticularUrl.length === 0) {
              inBetweenPreviewBanner1 = curr;
            } else {
              curr.showForParticularUrl.forEach((url) => {
                if (
                  decodeURIComponent(location.pathname)
                    .trim()
                    .includes(decodeURIComponent(url).trim())
                ) {
                  inBetweenPreviewBanner1 = curr;
                }
              });
            }
          } else if (parseInt(curr.placementPosition) === 3) {
            // working for in between preview banner
            if (curr.showForParticularUrl.length === 0) {
              inBetweenPreviewBanner2 = curr;
            } else {
              curr.showForParticularUrl.forEach((url) => {
                if (
                  decodeURIComponent(location.pathname)
                    .trim()
                    .includes(decodeURIComponent(url).trim())
                ) {
                  inBetweenPreviewBanner2 = curr;
                }
              });
            }
          }
        });

        setProductListingPageBanners({
          topbanner: topPreviewBanner,
          inBetweenProductBanner1: inBetweenPreviewBanner1,
          inBetweenProductBanner2: inBetweenPreviewBanner2,
        });
      }
    } catch (error) {
      console.error(error.message);
    }
  };

  const resetPagePositions = async () => {
    setCurrPage({
      page: 0,
      isLast: false,
    });
    setCurrUserPos(0);
  };

  const getSortingObj = useCallback((sort, key) => {
    switch (sort) {
      case "latest":
        return { createdAt: "desc" };

      case "high-to-low":
        return { sellingPrice: "desc" };

      case "low-to-high":
        return { sellingPrice: "asc" };

      case "discount":
        return { webDiscount: "desc" };
      default:
        if (key === "isSpotLightedProduct") return { spotLightIndex: "asc" };
        if (key === "isStartingAt999") return { startingAt999Index: "asc" };
        if (key === "ocassionCategory") return { ocassionCategoryIndex: "asc" };
        return {
          index: "asc",
        };
    }
  }, []);

  const getProducts = async ({ key, text, sort, projectionString }) => {
    currPage.page === 0 &&
      setPreviewProducts((prev) => ({ ...prev, isLoading: true }));
    const res = await get_product_by_query_object({
      BaseUrl: REACT_APP_BACKEND_URL,
      queryObject: {
        [key]: key === "name" ? { $regex: text, $options: "i" } : text,
        active: true,
        isPreviewInListing: true,
        "listingPriceHubWise.hub": locationData?.selectedLocation?.hubId,
      },
      skip: currPage.page * 54,
      limit: 54,
      sortingObj: getSortingObj(sort, key),
      projectionString,
    });
    setTotalProductsCount(res.productsCount);

    if (res.products.length <= 0 && currPage.page !== 0) {
      setCurrPage({
        ...currPage,
        isLast: true,
      });
      return;
    }
    let newProducts = [];
    if (currPage.page !== 0) {
      newProducts = previewProducts.previewProductData.map((curr) => curr);
    }
    res.products.forEach((element) => {
      newProducts.push(element);
    });

    setPreviewProducts((prev) => ({
      previewProductData: newProducts,
      isLoading: false,
    }));
  };
  // ------------------> fetch products for and return the fetched products
  const fetchProductsByQuery = async (
    queryObject,
    skip,
    limit,
    sortingObj,
    projectionString,
    pageLocation
  ) => {
    const res = await get_product_by_query_object({
      BaseUrl: REACT_APP_BACKEND_URL,
      queryObject: { ...queryObject, active: true },
      skip,
      limit,
      sortingObj,
      hubId: locationData?.selectedLocation?.hubId,
      projectionString,
    });

    if (res.products.length === 0 && pageLocation === "_SINGLE_PRODUCT_PAGE")
      navigate("/");

    return res.products;
  };

  // get product array using search text
  // const getBySearchText = async (searchText) => {
  //   try {
  //     const res = await get_product_by_query_object({
  //       BaseUrl: REACT_APP_BACKEND_URL,
  //       queryObject: {
  //         $or: [
  //           { name: { $regex: searchText?.trim(), $options: "i" } }, // Case-insensitive search on productName
  //           { _id: { $regex: searchText?.trim(), $options: "i" } }, // Match against _id
  //           { tags: { $regex: searchText?.trim(), $options: "i" } }, // Match against _id
  //         ],
  //         active: true,
  //         isPreviewInListing: true,
  //       },
  //       skip: 0,
  //       limit: 8,
  //       sortingObj: {},
  //       hubId: locationData?.selectedLocation?.hubId,
  //       projectionString:
  //         "_id name images sellingPrice costPrice webDiscount tags listingPriceHubWise",
  //     });

  //     return res.products;
  //   } catch (error) {
  //     console.log(error);
  //   }
  // };

  const getBySearchText = async (searchText) => {
    try {
      const searchAggregationPipeline = [
        {
          $facet: {
            fullMatch: [
              {
                $match: {
                  $or: [
                    { name: { $regex: `^${searchText}$`, $options: "i" } },
                    { _id: { $regex: `^${searchText}$`, $options: "i" } },
                    { tags: { $regex: `^${searchText}$`, $options: "i" } },
                  ],
                  active: true,
                  isPreviewInListing: true,
                },
              },
              { $limit: 8 },
            ],
            inOrderMatch: [
              {
                $match: {
                  name: {
                    $regex: `.*${searchText.split(/\s+/).join(".*")}.*`,
                    $options: "i",
                  },

                  active: true,
                  isPreviewInListing: true,
                },
              },
              { $limit: 8 },
            ],
            anyOrderMatch: [
              {
                $match: {
                  $and: searchText.split(/\s+/).map((word) => ({
                    $or: [
                      { name: { $regex: word, $options: "i" } },
                      // { _id: { $regex: word, $options: "i" } },  will be used like this if required
                      // { tags: { $regex: word, $options: "i" } }
                    ],
                  })),
                  active: true,
                  isPreviewInListing: true,
                },
              },
              { $limit: 8 },
            ],
            atLeastOneWordMatch: [
              {
                $match: {
                  $or: searchText.split(/\s+/).map((word) => ({
                    $or: [{ name: { $regex: word, $options: "i" } }],
                  })),
                  active: true,
                  isPreviewInListing: true,
                },
              },
              { $limit: 8 },
            ],
          },
        },
        {
          $project: {
            finalResults: {
              $concatArrays: [
                { $ifNull: ["$fullMatch", []] },
                { $ifNull: ["$inOrderMatch", []] },
                { $ifNull: ["$anyOrderMatch", []] },
                { $ifNull: ["$atLeastOneWordMatch", []] },
              ],
            },
          },
        },
        { $unwind: "$finalResults" },
        { $replaceRoot: { newRoot: "$finalResults" } },
        {
          $project: {
            _id: 1,
            name: 1,
            images: 1,
            sellingPrice: 1,
            costPrice: 1,
            webDiscount: 1,
            tags: 1,
            listingPriceHubWise: 1,
          },
        },
        { $limit: 8 },
      ];
      const res = await get_product_by_query_object({
        BaseUrl: REACT_APP_BACKEND_URL,
        hubId: locationData?.selectedLocation?.hubId,
        pipeline: searchAggregationPipeline,
      });
      return res.products;
    } catch (error) {
      console.log(error);
    }
  };

  const filterService = async () => {};

  return (
    <ProductPreviewContext.Provider
      value={{
        getBySearchText,
        previewProducts,
        showFilter,
        setShowFilter,
        setPreviewProducts,
        currPage,
        setCurrPage,
        resetPagePositions,
        filterService,
        getProducts,
        currUserPos,
        setCurrUserPos,
        totalProductsCount,
        setTotalProductsCount,
        fetchProductsByQuery,
        productListingpageBanners,
      }}
    >
      {children}
    </ProductPreviewContext.Provider>
  );
};

export default ProductPreviewContext;
