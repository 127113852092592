import React, { useContext } from "react";
import ResponsiveContext from "../../context/ResponsiveContext";

export default function HomeSkeleton() {
  const { screenWidth } = useContext(ResponsiveContext);
  const widthOfASingleCardSpace = screenWidth > 700 ? 10 : 21;
  const heightOfASingleCardSpace = widthOfASingleCardSpace * 1.2;
  const rowGapBetweenEachCard = 4;
  const inlinePadding = 4;
  const array = new Array(16).fill(0);

  return (
    <div className="flex flex-col justify-center items-center relative  md:top-[6rem]">
      {screenWidth < 768 && (
        <>
          <div
            style={{
              width: "calc(100vw - 1.8rem)",
            }}
            className="flex w-full justify-between h-[2rem]  mt-2 mb-2"
          >
            <div className="ebo_skeleton rounded-xl w-[3.5rem] h-full"></div>
            <div className="flex gap-3">
              <div className="ebo_skeleton rounded-full h-full w-[2rem]"></div>
              <div className="ebo_skeleton rounded-full h-full w-[2rem]"></div>
            </div>
          </div>
          <div
            style={{
              width: "calc(100vw - 1.8rem)",
            }}
            className="mt-1 mb-4 w-full "
          >
            <div className="  ebo_skeleton w-[15rem] h-[1rem] rounded-md mb-1"></div>
            <div className="  ebo_skeleton w-[8rem] h-[1rem] rounded-md"></div>
          </div>
          <div
            style={{
              width: "calc(100vw - 1.8rem)",
            }}
            className="flex w-full ebo_skeleton rounded-xl justify-between h-[2.5rem] mb-4"
          ></div>
        </>
      )}

      <div className="w-full flex flex-col items-center px-1 justify-center">
        <div
          style={{
            resizeMode: "stretch",
            width: "calc(100vw - 1.8rem)",
            aspectRatio: screenWidth > 600 ? 4 / 1 : 2 / 1,
          }}
          className="ebo_skeleton rounded-xl"
        ></div>

        <div
          style={{}}
          className=" rounded-full  h-[.5rem] w-[10rem] mt-[10px] "
        />
      </div>
      {/* top categories list skeleton */}
      <p className={`w-full flex items-center justify-center`}>
        {" "}
        <p
          className={`ebo_skeleton  text-center font-[600] h-[16px] w-[15rem] rounded-full mb-[20px]`}
        ></p>
      </p>
      <div
        style={{
          paddingInline: inlinePadding + "vw",
          rowGap: rowGapBetweenEachCard + "vw",
        }}
        className={`flex flex-row flex-wrap justify-between `}
      >
        {array?.map((curr, idx) => {
          return (
            <div
              key={idx}
              style={{
                borderWidth: 1,
                borderColor: "#f0f0f0",
                borderRadius: 12,
                overflow: "hidden",
              }}
            >
              <div
                style={{
                  resizeMode: "stretch",
                  width: widthOfASingleCardSpace + "vw",
                  height: heightOfASingleCardSpace + "vw",
                }}
                className="ebo_skeleton"
              ></div>
            </div>
          );
        })}
      </div>
      {/* home offer banner */}
      <div className="w-full flex flex-col items-center mt-[8px] justify-center">
        <div
          style={{
            resizeMode: "stretch",
            width: "95%",
            height: "7.5rem",
          }}
          className="ebo_skeleton rounded-xl"
        ></div>
      </div>
      <CommonSkeleton screenWidth={screenWidth} />
      <CommonSkeleton screenWidth={screenWidth} />
    </div>
  );
}

const CommonSkeleton = ({ screenWidth }) => {
  return (
    <div className="w-full flex flex-col items-center mt-[8px] justify-center">
      <p className={`w-[95%] flex items-center justify-start`}>
        <p
          className={`ebo_skeleton text-center font-[600] h-[16px] w-[15rem] rounded-full mb-[10px]`}
        ></p>
      </p>
      <div
        style={
          screenWidth > 768
            ? {
                width: "95%",
                height: "10rem",
              }
            : {
                width: "95%",
                height: "8rem",
              }
        }
        className="ebo_skeleton rounded-xl"
      ></div>
    </div>
  );
};
