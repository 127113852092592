import React, { useContext, useEffect, useRef } from "react";
import { insideHubBoundary } from "../../../../../functions/location/availablityChecks";
import GLobalStoreContext from "../../../../../context/GlobalStoreContext";
let hubCheckimeOutId = null;
let geoCodeTimeOutId = null;
let mapZoomLevel = 18;
export default function Map({ mapContainerRef, states, setStates }) {
  let map = states.map;
  let marker = states.marker;
  const { storeStates } = useContext(GLobalStoreContext);

  mapContainerRef = useRef();
  useEffect(() => {
    onInitialLoadOfMap(0);
    // eslint-disable-next-line
  }, [storeStates.isGoogleMapsScriptLoaded]);

  useEffect(() => {
    if (states.isChangeMapPosition) {
      console.log("state lat lng s", states.lat, states.lng);
      setStates((p) => ({ ...p, isChangeMapPosition: false }));
      map.setZoom(mapZoomLevel);
      map && map.setCenter({ lat: states.lat, lng: states.lng });
      map && placeMarker({ lat: states.lat, lng: states.lng });
    }
    // eslint-disable-next-line
  }, [states.isChangeMapPosition]);

  // --------- query debounce ------------
  const debounce = (func, delay = 500) => {
    return function (...args) {
      clearTimeout(geoCodeTimeOutId);
      geoCodeTimeOutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const hubDebounce = (func, delay = 500) => {
    return function (...args) {
      clearTimeout(hubCheckimeOutId);
      hubCheckimeOutId = setTimeout(() => {
        func(...args);
      }, delay);
    };
  };

  const debouncedGeoCodeApi = debounce(
    (location) => reverseGeocode(location),
    200
  );

  const debouncedHubCheck = hubDebounce((location) => hubCheck(location), 1500);

  const onInitialLoadOfMap = (attempt) => {
    if (!mapContainerRef && attempt < 5) {
      setTimeout(() => {
        onInitialLoadOfMap(attempt + 1);
      }, 200);
    }
    if (!map && mapContainerRef && storeStates.isGoogleMapsScriptLoaded) {
      map = new window.google.maps.Map(mapContainerRef.current, {
        center: {
          lat: parseFloat(states.lat) || 20,
          lng: parseFloat(states.lng) || 80,
        },
        gestureHandling: "greedy",
        zoom: mapZoomLevel,
        minZoom: 5,
        disableDefaultUI: true,
        mapId: "c399cc45fb048581",
      });

      setStates((p) => ({
        ...p,
        map: map,
      }));

      placeMarker({
        lat: parseFloat(states.lat) || 20,
        lng: parseFloat(states.lng) || 80,
      });
      console.log("onInitialLoadOfMap", states);
      map.addListener("center_changed", function () {
        const newCenter = map.getCenter();
        placeMarker({ lat: newCenter.lat(), lng: newCenter.lng() });
      });
    } else {
      // change the map center only
      console.log("have to changge the cneter");
    }
  };

  const placeMarker = async (location) => {
    if (!marker) {
      marker = new window.google.maps.Marker({
        map: map,
        background: "#2136d4",
      });
      setStates((p) => ({ ...p, marker }));
    }

    if (!states.loadingPointLocation) {
      setStates((p) => ({
        ...p,
        loadingPointLocation: true,
        serviceAvailable: false,
        noServiceAvailable: false,
      }));
    }

    marker.setPosition(location);
    debouncedHubCheck(location);
  };

  const hubCheck = async (location) => {
    const isPointInPolygon = await insideHubBoundary([
      location.lng,
      location.lat,
    ]);
    if (isPointInPolygon.isInside) {
      setStates((p) => ({
        ...p,
        hubId: isPointInPolygon.foundInWhichHub,
        serviceAvailable: true,
        noServiceAvailable: false,
        loadingReverseGeoCode: true,
      }));
      debouncedGeoCodeApi(location);
    } else {
      setStates((p) => ({
        ...p,
        loadingPointLocation: false,
        noServiceAvailable: true,
        serviceAvailable: false,
      }));
    }
  };

  const reverseGeocode = (location) => {
    console.log(location);
    const geocoder = new window.google.maps.Geocoder();
    geocoder.geocode({ location: location }, (results, status) => {
      if (status === "OK") {
        if (results[0]) {
          const addressComponents = results[0].address_components;

          const addressDetails = {
            placeId: results[0].place_id,
            formatedAddress: results.find(
              (currAddress) => currAddress.formatted_address.length <= 120
            ).formatted_address,
            streetNumber:
              addressComponents.find((component) =>
                component.types.includes("street_number")
              )?.long_name || "",
            neighborhood:
              addressComponents.find((component) =>
                component.types.includes("neighborhood")
              )?.long_name || "",
            streetName:
              addressComponents.find((component) =>
                component.types.includes("route")
              )?.long_name || "",
            s_locality1:
              addressComponents.find((component) =>
                component.types.includes("sublocality_level_1")
              )?.long_name || "",
            s_locality2:
              addressComponents.find((component) =>
                component.types.includes("sublocality_level_2")
              )?.long_name || "",
            s_locality3:
              addressComponents.find((component) =>
                component.types.includes("sublocality_level_3")
              )?.long_name || "",
            city:
              addressComponents.find((component) =>
                component.types.includes("locality")
              )?.long_name || "",
            pincode:
              addressComponents.find((component) =>
                component.types.includes("postal_code")
              )?.long_name || "",
            state:
              addressComponents.find((component) =>
                component.types.includes("administrative_area_level_1")
              )?.long_name || "",
            premise:
              addressComponents.find((component) =>
                component.types.includes("premise")
              )?.long_name || "",
          };
          setStates((p) => ({
            ...p,
            ...addressDetails,
            lat: location.lat,
            lng: location.lng,
            loadingReverseGeoCode: false,
            loadingPointLocation: false,
          }));
        } else {
          console.warn("No results found");
        }
      } else {
        console.error("Geocoder failed due to:", status);
      }
    });
  };

  return (
    <div
      ref={mapContainerRef}
      className=" absolute top-0 w-full h-[85%] bg-blue-50 z-[-1] "
    ></div>
  );
}
