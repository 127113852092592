import React from "react";

export default function ServiceableCities({ states, setStates }) {
  if (states.showServiceCites) {
    return (
      <div className="flex flex-col w-full bg-gradient-to-b rounded-xl px-4 mt-2  from-blue-50 to-white">
        <div className="w-full text-[.875rem] px-1  text-[grey] pt-2  ">
          Serviceable city
        </div>
        {/* serviceable cities container */}
        <div className="flex  text-[.875rem] gap-6 mt-2 w-full">
          {serviceableArea.map((curr) => (
            <div
              onClick={() => {
                setStates((p) => ({
                  ...p,
                  lat: curr.lat,
                  lng: curr.lng,
                  showMap: true,
                  defaultUi: false,
                  isPredictionsShow: false,
                  loadingPredictions: false,
                  showServiceCites: false,
                  isChangeMapPosition: true,
                }));
              }}
              className="flex relative items-center gap-2 flex-col "
            >
              <img
                className="rounded-xl w-[96px] h-[66px]"
                src={curr.img}
                alt="siliguri"
              />
              <span>{curr.label}</span>
            </div>
          ))}
        </div>
      </div>
    );
  }
}
const serviceableArea = [
  {
    city: "navi mumbai",
    img: "https://a.travel-assets.com/findyours-php/viewfinder/images/res70/472000/472847-India.jpg",
    label: "Navi Mumbai",
    lat: 19.04360522319033,
    lng: 73.07177922952339,
  },
  {
    city: "siliguri",
    img: "https://www.incredibleindia.org/content/dam/incredible-india-v2/images/places/siliguri/Coronation-6.jpg",
    label: "Siliguri",
    lat: 26.71783896797181,
    lng: 88.42246533853076,
  },
];
