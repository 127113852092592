import React, { memo, useContext } from "react";
import { AiOutlineRight } from "react-icons/ai";

import { useNavigate } from "react-router-dom";
import ProductPreviewContext from "../../../context/ProductPreviewContext";

function SearchResList(props) {
  const navigate = useNavigate();
  const { resetPagePositions } = useContext(ProductPreviewContext);
  return (
    <div className="sr-res">
      <h3 className="sr-res-h3">
        {props.productArr.length === 0
          ? `Couldn't find results for "${props.srData}"`
          : `Showing results for "${props.srData}"`}
      </h3>
      <div className=" scroll-bar-remove h-[80vh] md:h-[25rem] flex flex-col w-full overflow-scroll">
        {props.productArr.map((currProduct) => {
          return (
            <div
              onClick={() => {
                navigate(
                  `/products/decor/${currProduct._id}/${currProduct.name
                    .trim()
                    .replace("&", "and")
                    .split(" ")
                    .join("-")}`
                );
              }}
              key={currProduct._id}
              className="flex gap-4 relative py-[1.5rem] px-[.5rem] border-b-4 border-[#8080802f]"
            >
              <img
                src={currProduct.images[0]}
                className=" w-[5rem] h-[5rem] rounded-xl "
                alt="logo"
              />
              <div className="flex flex-col py-2 h-full  justify-between">
                <h3 className="text-[1rem] font-[600] text-[#171717] md:text-[1.1rem]">
                  {currProduct.name.substring(0, 50)}
                  {currProduct.name.length > 50 && "..."}
                </h3>

                <div className="flex items-center gap-3">
                  <div className=" text-[#171717] text-[1rem] ">
                    &#x20B9;{currProduct.sellingPrice}
                  </div>
                  <div className=" line-through text-[#17171785] text-[.825rem]">
                    &#x20B9;{currProduct.costPrice}
                  </div>
                  <div className="text-[#00a807] text-[.925rem]">
                    {currProduct.webDiscount}% off
                  </div>
                </div>
                <div className="sr-res-data-offer"></div>
              </div>
              <div
                onClick={() => {
                  props.setSrData("");
                  props.handelCloseOfContainer();
                }}
                className="sr-res-list-item-arrow"
              >
                <div className=" absolute text-[black] right-[.5rem] top-[40%] w-4 cursor-pointer text-[1rem]">
                  <AiOutlineRight />
                </div>
              </div>
            </div>
          );
        })}
      </div>
      <div
        onClick={() => {
          resetPagePositions();
          navigate(`/products/search/${props.srData}`);
          props.handelCloseOfContainer();
        }}
        className=" md:bottom-[1rem]  w-full absolute bottom-[4.5rem] bg-[white]  text-[#2136d4] items-center my-4 cursor-pointer flex justify-center  text-[1rem] "
      >
        See all <AiOutlineRight className="text-[1rem]" />{" "}
      </div>
    </div>
  );
}

export default memo(SearchResList);
