import React, { useContext, memo } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import AuthContext from "../../context/AuthContext"; 
import Profile from "./profileDetails/Profile";
import SearchBar from "./search/SearchBar";
import { CiUser, CiHeart, CiShoppingCart } from "react-icons/ci";
import ResponsiveContext from "../../context/ResponsiveContext";
import CompRenderingContext from "../../context/CompRenderingContext";
import NavbarSm from "./NavbarSm";
import "./Navbar.css";
import NavbarAddressv2 from "./NavbarAddressv2";

function Navbar() {
  const { pathname } = useLocation();
  // ----------------- ** CONTEXT ** --------------------
  const { user, logout } = useContext(AuthContext);
  const { setNavAddressPop, setIsLoginModal } =
    useContext(CompRenderingContext);
  const { screenWidth } = useContext(ResponsiveContext);
  const navigate = useNavigate();

  const onWishListClick = () => {
    if (user) {
      navigate("/profile/wishlist");
    } else {
      setIsLoginModal(true);
    }
  };

  const handleClickOnEboLogo = () => {
    setNavAddressPop(false);
  };

  if (pathname === "/delete/user") {
    return <></>;
  }

  return (
    <>
      {screenWidth > 768 ? (
        <nav className={`navbar-i z-[20] `}>
          <Link
            to="/"
            onClick={handleClickOnEboLogo}
            className="flex justify-center items-center flex-1"
          >
            <h1
              style={{
                fontFamily: "'Josefin Sans', sans-serif",
              }}
              className="  relative text-[2rem] flex justify-center items-end text-[#2136d4] font-[800]  "
            >
              ebo
            </h1>
          </Link>
          <NavbarAddressv2 />
          <SearchBar />

          <div className="flex justify-end gap-[1.5rem] items-center mr-[1rem] ">
            <div
              onClick={() => {
                !user && setIsLoginModal(true);
              }}
              className="text-[1.5rem] flex flex-col items-center justify-center gap-1 text-[black] cursor-pointer  nav-profile"
            >
              <CiUser />
              <p className=" text-[black] text-[.825rem]  ">
                {user ? "Profile" : "Login"}
              </p>

              {user !== null && <Profile user={user} logout={logout} />}
            </div>

            <div
              onClick={onWishListClick}
              className="text-[1.5rem] flex flex-col items-center justify-center gap-1 text-[black] cursor-pointer"
            >
              <CiHeart />
              <p className=" text-[black] text-[.825rem]  ">Wishlist</p>
            </div>

            <div
              onClick={() => navigate("/checkout")}
              to={"/checkout"}
              className="text-[1.5rem] flex flex-col items-center justify-center gap-1 text-[black] cursor-pointer"
            >
              <CiShoppingCart />
              <p className=" text-[black] text-[.825rem]   ">Cart</p>
            </div>
          </div>
        </nav>
      ) : (
        <NavbarSm
          handleClickOnEboLogo={handleClickOnEboLogo}
          onWishListClick={onWishListClick}
          user={user}
          logout={logout}
        />
      )}
    </>
  );
}

export default memo(Navbar);
