import { useContext, useEffect } from "react";
import HomePageContext from "../context/HomepageContext";
import AddressContext from "../context/AddressContext";
import OfferContext from "../context/OfferContext";
import AuthContext from "../context/AuthContext";
import CartContext from "../context/CartContext";
import { handleDefaultLocationFetch } from "../functions/location/handleLocation";
import CompRenderingContext from "../context/CompRenderingContext";
import { logUserLocationApi } from "../api/userApi";
import GLobalStoreContext from "../context/GlobalStoreContext";

const useFetchEboData = () => {
  const { fetchHomeData, homeInitialData } = useContext(HomePageContext);
  const { user } = useContext(AuthContext);
  const { handleCartPresence } = useContext(CartContext);
  const { fetchAddressData } = useContext(AddressContext);
  const { offerState, getOffers } = useContext(OfferContext);
  const { setLocationData, locationData } = useContext(CompRenderingContext);
  const { storeStates, setStoreStates } = useContext(GLobalStoreContext);

  useEffect(() => {
    async function callMethods() {
      logUserLocationApi({ isUserLoggedIn: user }); // user traffic
      const hubId = await handleDefaultLocationFetch(
        setLocationData,
        locationData,
        storeStates.isGoogleMapsScriptLoaded,
        setStoreStates
      );
      await fetchHomeData({ hubId });
      if (user) {
        await fetchAddressData();
        await handleCartPresence();
      }
      if (offerState.offers.length === 0 || !offerState.offers) {
        await getOffers();
      }
    }
    callMethods();
    // eslint-disable-next-line
  }, [user?.id]);

  useEffect(() => {
    async function callMethods() {
      homeInitialData?.initialData && (await fetchHomeData());
    }
    callMethods();
    // eslint-disable-next-line
  }, [locationData?.selectedLocation?.hubId]);

  return;
};

export default useFetchEboData;
