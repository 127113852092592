import React, { memo } from "react";
import { Link } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";

function FooterLeft() {
  const categoryList = [
    {
      text: "Birthday",
      code: 0,
    },
    {
      text: "Anniversary",
      code: 1,
    },
    {
      text: "kid's Party",
      code: 2,
    },
    {
      text: "Baby Shower",
      code: 3,
    },
    {
      text: "Welcome",
      code: 4,
    },
    {
      text: "BYOD",
      code: 5,
    },
  ];
  const handleCategDown = () => {
    document
      .querySelector(".footer-left-categories")
      .classList.toggle("footer-left-categories-anime");
  };
  const handlepolicyDown = () => {
    document
      .querySelector(".footer-left-policies")
      .classList.toggle("footer-left-policies-anime");
  };
  const handleaboutDown = () => {
    document
      .querySelector(".footer-left-about")
      .classList.toggle("footer-left-about-anime");
  };
  return (
    <div className="footer-left">
      <div className="footer-left-cont gap-4 md:gap-8">
        <div className="footer-left-categories  flex flex-col text-[.825rem]">
          <div
            onClick={handleCategDown}
            className="footer-left-h3 font-[600] text-[.925rem] "
          >
            Categories
            <div className="footer-left-i">
              <BiChevronDown />
            </div>
          </div>
          {categoryList.map((curr) => {
            return (
              <Link
                to={`/products/category/${curr.code}?occassion=${curr.text}`}
                key={curr.code}
                className="py-[3px]"
              >
                {" "}
                {curr.text}
              </Link>
            );
          })}
        </div>
        <div className="footer-left-policies flex flex-col text-[.825rem]">
          <div
            onClick={handlepolicyDown}
            className="footer-left-h3 font-[600] text-[.925rem]"
          >
            Policies
            <div className="footer-left-i">
              <BiChevronDown />
            </div>
          </div>

          <Link to="/privacy" className="py-[3px]">
            Privacy Policy
          </Link>

          <Link to="/terms" className="py-[3px]">
            Term & Conditions
          </Link>
          <Link to="/shippingPolicy" className="py-[3px]">
            Shipping policy
          </Link>
          <Link to="/cancellation " className="py-[3px]">
            Cancellation & refunds{" "}
          </Link>
        </div>
        <div className="footer-left-about  flex flex-col text-[.825rem]">
          <div
            onClick={handleaboutDown}
            className="footer-left-h3 font-[600] text-[.925rem]"
          >
            About
            <div className="footer-left-i">
              <BiChevronDown />
            </div>
          </div>
          <Link to="/help" className="py-[3px]">
            Contact
          </Link>
          <Link to="/help" className="py-[3px]">
            FAQ's
          </Link>
          <Link to="https://blogs.ebonow.com/" className="py-[3px]">
            Blog
          </Link>
        </div>
      </div>
    </div>
  );
}

export default memo(FooterLeft);
