import React, { useContext } from "react";
import AuthContext from "../context/AuthContext";
import { FaWhatsapp } from "react-icons/fa";
import { EBO_PHONE_NUMBER } from "../utils/constants";
export default function ErrorPage() {
  const { logout } = useContext(AuthContext);

  const msgContent = `Hi ebo, I'm facing some issues while exploring your website`;

  return (
    <div className="flex tracking-[.6px] leading-[22px] text-[#1717171] min-h-[100vh] w-full flex-col items-center justify-center ">
      <div className="flex  flex-col items-center justify-center ">
        <div className="flex flex-col w-full ">
          <p className="text-[2.2rem] mb-[.5rem] text-[#2136d4] font-[900]">
            Oops!
          </p>
          <p className="text-[1.2rem] font-[600] ">Something went wrong</p>
          <p className="text-[.9rem] ">Don't worry, our team is here to help</p>
        </div>
        <div className="flex mt-[1rem] w-full text-[.8rem] ">
          <div className="flex flex-col gap-2 ">
            <h2 className="text-[1rem] font-[600] ">Quick Suggestions</h2>
            <p>
              <button
                className=" bg-[#2136d4] text-[white] text-[.825rem] px-2 py-[1px] rounded-lg  "
                onClick={() => {
                  typeof window !== "undefined" && window.location.reload();
                }}
              >
                Refresh
              </button>{" "}
              the page
            </p>
            <p>
              <button
                className=" bg-[#2136d4] text-[white] text-[.825rem] px-2 py-[1px] rounded-lg  "
                onClick={() => {
                  logout();
                }}
              >
                Re-login
              </button>{" "}
              to your account{" "}
            </p>
          </div>
          <div className="h-[5rem] w-[2px] bg-[#2136d4] flex items-center justify-center mx-[1.5rem]  ">
            <span className="bg-[white]">or</span>
          </div>
          <div className="flex flex-col gap-2">
            <h2 className="text-[1rem] font-[600] ">Contact Us</h2>
            <p className="flex items-center gap-1">
              <FaWhatsapp className="text-[1rem] text-[#4FCE5D] " />
              <a href={`https://wa.me/${EBO_PHONE_NUMBER}?text=${msgContent}`}>
                Chat with us
              </a>
            </p>
            {/* <p>Get help on call</p> */}
          </div>
        </div>
      </div>
    </div>
  );
}
