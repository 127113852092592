import axios from "axios";
const BaseUrl = process.env.REACT_APP_BACKEND_URL;
/**
 * Fetches all orders for normal user
 * @param {string} token - The JWT token used for authenticating the user.
 * @param {string} url - The API base URL.
 * @returns {Promise} A Promise that resolves with the retrieved orders data (as array) or rejects with an error.
 */
const get_all_orders = async (token, url) => {
  try {
    const token = JSON.parse(localStorage?.getItem("user")).token;

    const res = await axios.get(`${url}/api/orders`, {
      headers: {
        Authorization: "Bearer " + token,
      },
    });
    return res.data;
  } catch (error) {
    console.log(error);
  }
};

/**
 * for getting the orders from database
 * @param {Object} {  BaseUrl,
  queryObject,
  skip,
  limit,sortingObj}
 * @return {Object}
 */
const get_orders_by_query_object = async ({
  queryObject,
  skip,
  limit,
  sortingObj,
}) => {
  try {
    const token = JSON.parse(localStorage?.getItem("user")).token;

    const res = await axios.post(
      `${BaseUrl}/api/orders/get_orders_by_query_object`,
      {
        queryObject,
        skip: skip || 0,
        limit: limit || 10,
        sortingObj: sortingObj || {},
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

const get_order_by_id_without_token_api = async (orderId) => {
  try {
    const res = await axios.get(
      `${BaseUrl}/api/orders/get_order_by_id_without_token/${orderId}`
    );
    return res.data;
  } catch (error) {
    return {
      isSuccess: false,
    };
  }
};
/**
 * for updating the orders 
 * @param {Object} {  BaseUrl,
  queryObject,
  skip,
  limit,sortingObj}
 * @return {Object}
 */
const update_orders_by_query_object = async ({ queryObject, updateObject }) => {
  try {
    const token = JSON.parse(localStorage?.getItem("user")).token;

    const res = await axios.post(
      `${BaseUrl}/api/orders/update_orders_by_query_object`,
      {
        queryObject,
        updateObject,
      },
      {
        headers: {
          Authorization: "Bearer " + token,
        },
      }
    );
    return res.data;
  } catch (error) {
    console.log(error);
    return {
      isSuccess: false,
      isError: true,
      message: error,
    };
  }
};

export {
  get_all_orders,
  update_orders_by_query_object,
  get_orders_by_query_object,
  get_order_by_id_without_token_api,
};
