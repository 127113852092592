import React, { useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import { CiUser, CiHeart, CiShoppingCart } from "react-icons/ci";
import SearchBar from "./search/SearchBar";
import CompRenderingContext from "../../context/CompRenderingContext";
import NavbarAddressv2 from "./NavbarAddressv2";
import DownloadApp from "../downloadApp/DownloadApp";
import Profile from "./profileDetails/Profile";

export default function NavbarSm({
  handleClickOnEboLogo,
  user,
  logout,
  onWishListClick,
}) {
  //  ----------------- CONTEXT ----------------
  const { setIsLoginModal } = useContext(CompRenderingContext);

  const navigate = useNavigate();
  return (
    <>
      <div className="bg-[white] z-50 flex flex-col justify-center ">
        {/* <div className="flex h-full  justify-between items-center px-3">
          <Link
            to="/"
            onClick={handleClickOnEboLogo}
            className="flex justify-center items-center"
          >
            <h1
              style={{
                fontFamily: "'Josefin Sans', sans-serif",
              }}
              className="relative top-[4px] text-[2rem] flex justify-center items-center text-[#2136d4] font-[800]  "
            >
              ebo
            </h1>
          </Link>
          <div className="flex p-2 text-[1.7rem] gap-3 justify-between items-center ">
            {!user ? (
              <label
                onClick={() => {
                  setIsLoginModal(true);
                }}
                htmlFor="auth-sidebar"
                className="flex flex-col items-center cursor-pointer"
              >
                <p className="text-[.925rem] rounded  mirror-Animation before:w-[50px] blue-gradient px-4 py-[3px] ">
                  Login
                </p>
              </label>
            ) : (
              <>
                <CiUser
                  onClick={() => {
                    navigate("/profile/myaccount");
                  }}
                />
                <CiHeart onClick={onWishListClick} />
              </>
            )}
          </div>
        </div> */}

        <DownloadApp />
        <div className="flex gap-4 w-[100vw] overflow-hidden pr-3">
          <div className="w-[60%]">
            <NavbarAddressv2 />
          </div>
          <div className="flex justify-end gap-[1rem] items-center flex-1 ">
            <div
              onClick={onWishListClick}
              className="text-[1.5rem] flex flex-col items-center justify-center gap-1 text-[black] cursor-pointer"
            >
              <CiHeart />
              <p className=" text-[black] text-[.825rem]  ">Wishlist</p>
            </div>

            <div
              onClick={() => {
                user ? navigate("/profile/myaccount") : setIsLoginModal(true);
              }}
              className="text-[1.5rem] flex flex-col items-center justify-center gap-1 text-[black] cursor-pointer  nav-profile"
            >
              <CiUser />
              <p className=" text-[black] text-[.825rem]  ">
                {user ? "Profile" : "Login"}
              </p>

              {user !== null && <Profile user={user} logout={logout} />}
            </div>
          </div>
        </div>
      </div>
      <SearchBar />
    </>
  );
}
